.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 100px;
  position: relative;
  width: 100%;
}
.row-content {
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  & > div {
    background-color: rgba($color: #ffffff, $alpha: 0.5);
    padding: 20px;
    backdrop-filter: blur(1px);
  }
  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.card {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 17px;
  font-weight: bolder;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0 0 7px rgba($color: #000000, $alpha: 0.1);
  transition: 0.4s;
  border: solid 5px transparent;
  overflow: hidden;
  position: relative;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &:hover {
    border: solid 5px var(--active);
    box-shadow: 0 0 10px 5px rgba($color: #000000, $alpha: 0.1);
  }
  .contenu {
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.4s;
    p {
      font-size: 15px;
      line-height: 20px;
      font-weight: normal;
    }
  }
  &:hover .contenu {
    opacity: 1;
  }
}
.card-descri {
  width: 100%;
  position: relative;
  padding-left: 20px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    background: var(--active);
    height: 100%;
    transition: 0.2s;
  }
  &:hover {
    &::before {
      width: 10px;
    }
  }
}
.carousel-root {
  width: 100%;
}
.column-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.action-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.qcm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 20px;
    }
  }
}
