#navbar {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  gap: 5px;
  align-items: center;
  #logo {
    width: 100px;
    object-fit: contain;
    cursor: pointer;
  }
}
#pathnav {
  position: fixed;
  top: 126px;
  padding: 10px 50px;
  width: 100%;
  background: var(--active);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  font-weight: normal;
  font-size: 17px;
  z-index: 100;
  box-shadow: 0px 2px 3px rgba($color: #000000, $alpha: 0.5);
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }
  a {
    flex-wrap: nowrap;
    overflow-y: hidden;
    text-decoration: none;
    color: #fff;
    height: 25px;
    font-weight: lighter;
    &:hover {
      text-decoration: underline;
    }
  }
  a::first-letter {
    text-transform: uppercase;
  }
  & > div:nth-last-child(1) > a {
    font-weight: bolder;
  }
}
@media screen and (max-width: 900px) {
  #navbar {
    padding: 20px;
  }
  #pathnav {
    padding: 10px 20px;
  }
}
