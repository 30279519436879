.grid-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  gap: 20px;
  flex-wrap: wrap;
}
.cours {
  width: 300px;
  transition: 0.4s;
  position: relative;
  border-radius: 0 0 7px 7px;
  box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
  & > .img-container {
    position: relative;
    height: 200px;
    & > img {
      height: 100%;
      object-fit: cover;
    }
    & > div {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($color: #000000, $alpha: 0.5);
      opacity: 0;
      transition: 0.4s;
      & > p {
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        color: white;
        padding: 10px;
        gap: 5px;
        border: solid 2px white;
        border-radius: 5px;
      }
    }
  }
  & > .cours-content {
    background-color: #f5f5f5;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
    & > div {
      & > h4 {
        margin-top: 10px;
        font-weight: normal;
        font-size: 17px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      & > h5 {
        font-weight: normal;
        font-size: 11px;
        text-transform: uppercase;
      }
    }

    & > .progressbar {
      position: relative;
      width: 100%;
      background-color: #afb0b1;
      height: 20px;
      border-radius: 10px;
      overflow: hidden;
      & > span {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: var(--active);
      }
      & > p {
        position: absolute;
        top: 50%;
        left: 50%;
        line-height: 10px;
        transform: translate(-50%, -50%);
        font-size: 12px;
        color: white;
      }
    }
  }
  &:hover {
    box-shadow: 0 0 5px rgb(95, 95, 95);
    & > .img-container > div {
      opacity: 1;
    }
  }
}
.list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  h3 {
    font-weight: normal;
    color: white;
    text-transform: uppercase;
    font-size: 20px !important;
  }
  .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.14);
    &>h4{
      font-weight: normal;
      cursor: pointer;
      &:hover{
        color: var(--active);
        font-weight: bold;
      }
    }
    &:hover{
      background: rgba($color: #000000, $alpha: 0.1);
    }
    & > .progressbar {
      position: relative;
      min-width: 200px;
      background-color: #afb0b1;
      height: 20px;
      border-radius: 10px;
      overflow: hidden;
      & > span {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: var(--active);
      }
      & > p {
        position: absolute;
        line-height: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 8px;
        color: white;
      }
    }
  }
  .list-item:nth-child(even) {
    background: rgba($color: #000000, $alpha: 0.05);
    &:hover{
      background: rgba($color: #000000, $alpha: 0.1);
    }
  }
}
@media screen and (max-width: 750px) {
  .list-container {
    h3{
      font-size: 15px !important;
    }
    .list-item {
      padding: 10px;
      & > .progressbar {
        display: none;
      }
    }
  }
}
