@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans-Regular.ttf");
}
* {
  font-family: "Open Sans";
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  letter-spacing: 1.5px;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 50px #efefef;
    border-radius: 10px;
    color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--theme);
    border-radius: 10px;
  }
}
body {
  overflow-x: hidden;
}
:root {
  --theme: #313d2f;
  --active: #728b6b;
}
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
p,
h1,
h2,
h3 {
  line-height: 30px;
}
#accueil {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  gap: 10px;
  padding: 20px;
  margin-top: 180px;
  box-shadow: 0 0px 5px rgba($color: #000000, $alpha: 0.1);
  border-radius: 7px;
  background-color: rgba($color: #000000, $alpha: 0.02);
  h2 {
    color: var(--theme);
    font-size: 2rem;
  }
  h3 {
    font-size: 25px;
  }
  & > p {
    font-size: 20px;
  }
}
div#alert {
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20000;
}
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #313d2f;
  padding: 20px;
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  a {
    color: white;
  }
}
.row-div {
  display: flex;
  flex-direction: row;
  & > div {
    width: 50%;
    min-width: 300px;
  }
  & > img {
    width: 50%;
    min-width: 300px;
  }
}

.article {
  & > img {
    shape-outside: circle();
    border-radius: 100%;
    width: 300px;
    float: left;
    margin-right: 20px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
  padding: 7px;
  font-size: 17px;
  border: solid 1px rgba($color: #000000, $alpha: 0.2);
  border-radius: 7px;
  transition: 0.2s;
  &:focus {
    outline: none;
    border: solid 1px var(--active);
    box-shadow: 0 0 5px var(--active);
  }
}

.col-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > input {
    width: 250px;
    padding: 7px;
    font-size: 17px;
    border: solid 1px rgba($color: #000000, $alpha: 0.2);
    border-radius: 7px;
    transition: 0.2s;
    &:focus {
      outline: none;
      border: solid 1px var(--active);
      box-shadow: 0 0 5px var(--active);
    }
  }
}
.underline {
  cursor: pointer;
  color: rgb(103, 163, 212);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.nav-btn {
  color: white;
  font-weight: bolder;
  padding: 10px 15px;
  background: var(--active);
  border: none;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background: var(--theme);
  }
}
div#loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#dialog {
  position: fixed;
  top: 0;
  left: 0;
  .dialog-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 1;
    }
    .dialog-content {
      background-color: rgb(255, 255, 255);
      box-shadow: 0 0px 20px rgb(68, 68, 68);
      border-radius: 7px;
      padding: 20px;
      position: fixed;
      top: 50%;
      left: 50%;
      min-width: 300px;
      max-height: 70vh;
      overflow-y: auto;
      transform: translate(-50%, -50%);
      z-index: 2;
      .dialog-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
#notFound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    font-size: 25px;
    color: #313d2f;
  }
}
#home{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  background: transparent;
  text-align: center;
  min-width: 300px;
  .caroussel{
    width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  #accueil {
    margin-top: 250px;
  }
}
@media screen and (max-width: 500px) {
  header > ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    justify-content: center;
  }
  #accueil>.row-content{
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }
}
